import { Divider, Heading } from "@chakra-ui/react";

export const CustomSectionHeader = ({
  children,
  headingStyle,
  borderDisplay,
}) => {
  return (
    <>
      <Heading
        fontSize={{
          base: "2xl",
          md: "3xl",
          lg: "3xl",
          "2xl": "4xl",
        }}
        fontWeight={"bold"}
        textAlign={"center"}
        noOfLines={3}
        sx={headingStyle}
      >
        {children}
      </Heading>
      <Divider
        w={"35px"}
        borderTopWidth={5}
        borderColor={"primary"}
        mx={"auto"}
        mt={5}
        display={borderDisplay}
      />
    </>
  );
};
