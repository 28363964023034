import { Box } from "@chakra-ui/react";
import React from "react";

export default function Container({ children, maxW = "1366px" }) {
  return (
    <>
      <Box maxW={maxW} margin={"auto"} px={5}>
        {children}
      </Box>
    </>
  );
}
