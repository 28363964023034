import BrandBar from "../../Components/Brands/BrandBar";
import { TrustedBy } from "../../Components/Brands/TrustedBy";
import Container from "../../Components/Container/Container";
import ExploreEmployers from "../../Components/ExploreEmployers/ExploreEmployers";
import ForEmployeesBanner from "../../Components/ForEmployeesBanner/ForEmployeesBanner";
import StartedWithKeeper from "../../Components/StartedWithKeeper/StartedWithKeeper";
import UnusedPaidLeaves from "../../Components/UnusedPaidLeaves/UnusedPaidLeaves";

export default function ForEmployees() {
  return (
    <>
      <Container maxW="1280px">
        <ForEmployeesBanner />
        <BrandBar />
      </Container>
      <TrustedBy />
      <Container maxW="1280px">
        <UnusedPaidLeaves />
      </Container>
      <StartedWithKeeper />
      <Container maxW="1280px">
        <ExploreEmployers />
      </Container>
    </>
  );
}
