import { Box } from "@chakra-ui/react";
import React from "react";

export const CustomContainer = ({ children }) => {
  return (
    <Box w={{ base: "full", md: "98%" }} mx={"auto"}>
      {children}
    </Box>
  );
};
