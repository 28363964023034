import { Box, Flex, Heading, Image, VStack } from "@chakra-ui/react";
import React from "react";
import calenderImg from "../../Assets/calendar.png";
import freeImg from "../../Assets/free.png";
import integrationImg from "../../Assets/integration.png";

const plans = [
  {
    title: "Zero Cost to Employer",
    image: freeImg,
  },
  {
    title: "No integration required",
    image: integrationImg,
  },
  {
    title: "No monthly deduction needed",
    image: calenderImg,
  },
];

export default function FreePlan() {
  return (
    <Box as="section" py={{ base: 7, md: 12 }}>
      <Flex
        flexDir={{ base: "column", md: "column", lg: "row" }}
        justify={"space-between"}
        alignItems={"center"}
        gap={{ base: 10, md: 16 }}
      >
        {plans.map((plan, index) => (
          <PlanCard plan={plan} key={index} idx={index} />
        ))}
      </Flex>
    </Box>
  );
}

const PlanCard = ({ plan, idx }) => {
  const getDynamicHeight = (index) => {
    switch (index) {
      case 0:
        return { width: "150px", height: "150px" };

      case 1:
        return { width: "200px", height: "150px" };

      case 2:
        return { width: "145px", height: "150px" };

      default:
        return { width: "35px", height: "150px" };
    }
  };
  return (
    <VStack maxW={200} textAlign={"center"}>
      <Box sx={getDynamicHeight(idx)}>
        <Image
          src={plan.image}
          alt={plan.title}
          w={"full"}
          h={"full"}
          objectFit={"contain"}
        />
      </Box>
      <Heading as={"h3"} size={"md"} lineHeight={7}>
        {plan.title}
      </Heading>
    </VStack>
  );
};
