import { Box, Heading, Image, Text, VStack } from "@chakra-ui/react";
import financialDiagram from "../../Assets/financial_digram.png";
import financialDiagramMobile from "../../Assets/financial_digram_mobile.png";

export default function FinancialStep() {
  return (
    <Box as="section" pb={{ base: 5, md: 5 }}>
      <VStack
        spacing={{ base: 3, md: 3, lg: 5 }}
        pb={{ base: 12, xl: 16 }}
        textAlign="center"
      >
        <Heading
          as="h1"
          size="xl"
          maxW={"xl"}
          color={"primary"}
          fontWeight="bold"
        >
          Unfair advantage for your employees at every financial step
        </Heading>

        <Text maxW="2xl" fontSize="lg">
          Give your team an unfair advantage at every step of their financial
          journey by unlocking the hidden value of paid leaves.
        </Text>
      </VStack>
      <Box>
        <Image
          src={financialDiagram}
          alt="Financial Diagram"
          w={"90%"}
          h={"full"}
          mt={-10}
          display={{ base: "none", md: "block" }}
          mx={"auto"}
        />
        <Image
          src={financialDiagramMobile}
          alt="Financial Diagram"
          w={"full"}
          h={"full"}
          mt={-10}
          display={{ base: "block", md: "none" }}
        />
      </Box>
    </Box>
  );
}
