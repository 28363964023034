import {
  Box,
  Collapse,
  Flex,
  IconButton,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import logo from "../../Assets/logo2.png";
import Container from "../Container/Container";
import { DesktopNavbar } from "./DesktopNavbar/DesktopNavbar";
import { MobileNavbar } from "./MobileNavbar/MobileNavbar";

const navItem = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "For Employees",
    href: "/for-employees",
  },
  {
    label: "Blog",
    href: "/blogs",
  },
  {
    label: "Contact Us",
    href: "/contact-us",
  },
];

export const Navbar = () => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  return (
    <Container>
      <Box py={3}>
        <Flex
          alignItems={"center"}
          flexDir={"row-reverse"}
          justifyContent={"space-between"}
          px={{ base: 0, md: 5 }}
        >
          <Flex display={{ base: "flex", md: "none" }} align={"center"}>
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <MdClose w={6} h={6} style={{ color: "#5956E9" }} />
                ) : (
                  <GiHamburgerMenu w={6} h={6} style={{ color: "#5956E9" }} />
                )
              }
              variant={"solid"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex
            flex={{ base: 1 }}
            justify={{ base: "start", md: "space-between" }}
            alignItems={"center"}
          >
            <Flex align={"center"} gap={2} as={Link} to="/">
              <Box
                w={{ base: "30px", md: "40px" }}
                h={{ base: "30px", md: "40px" }}
                display={{ base: "block", md: "none" }}
              >
                <Image w={"full"} src={logo} />
              </Box>
              <Text
                textAlign={{ base: "center", md: "left" }}
                fontFamily={"heading"}
                fontWeight={"bold"}
                fontSize={{ base: "2xl", md: "4xl" }}
                textColor={"primary"}
                display={{ base: "none", md: "block" }}
              >
                Keeper
              </Text>
            </Flex>

            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNavbar navItem={navItem} />
            </Flex>
          </Flex>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNavbar navItem={navItem} onClose={onClose} />
        </Collapse>
      </Box>
    </Container>
  );
};
