import { Box, Circle, Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import TextCarousel from "../TextCarousel/TextCarousel";

const keyFeatures = [
  "No financial stress",
  "Apply in less than 5 minutes",
  "Get more rewards",
];
export default function KeyFeatures() {
  return (
    <>
      <Box display={{ base: "block", md: "none" }}>
        <HStack justify="center">
          <TextCarousel>
            {keyFeatures.map((feature, i) => (
              <Box
                key={feature}
                px={5}
                style={i === 1 ? { width: 275 } : { width: "auto" }}
              >
                <TextCard>{feature}</TextCard>
              </Box>
            ))}
          </TextCarousel>
        </HStack>
      </Box>

      <Box display={{ base: "none", md: "block" }}>
        <HStack spacing={20} justify="center">
          {keyFeatures.map((feature, i) => (
            <TextCard key={feature}>{feature}</TextCard>
          ))}
        </HStack>
      </Box>
    </>
  );
}

const TextCard = ({ children }) => {
  return (
    <HStack spacing={2}>
      <Flex gap={2} align={"center"}>
        <Circle size="2.5" bg="primary" />
        <Text>{children}</Text>
      </Flex>
    </HStack>
  );
};
