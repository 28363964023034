import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { IoMdAlert } from "react-icons/io";
import formatNumberWithCommas from "../../utilities/formatNumberWithCommas";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(?!.*\b(gmail|yahoo|outlook|hotmail|aol|icloud|zoho|yandex|protonmail|live|msn|gmx|mail|rediffmail)\.com$)((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TELEGRAM_BOT_API_URL = process.env.REACT_APP_TELEGRAM_BOT_API_URL;

function calculateLeaveValue(totalAmount) {
  return Math.floor(totalAmount * 0.6);
}

export default function CheckLeaveValueForm({ setIsFormSubmitted }) {
  const [state, setState] = useState("initial");

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    setState("calculating");
    const payload = {
      chat_id: "-908107678",
      text: `Leave check request\n\nEmail: ${
        data.email
      }\nMonthly Salary: ₹${formatNumberWithCommas(data.salary)}`,
    };
    try {
      const response = await axios.post(TELEGRAM_BOT_API_URL, payload);
      if (response.data.ok) {
        setState("success");
        setIsFormSubmitted?.(true);
      }
    } catch (error) {
      setState("error");
      console.error(error);
    }
  };
  return (
    <>
      {state === "success" ? (
        <VStack spacing={5}>
          <CalculatedLeaveValue
            leaveValue={calculateLeaveValue(getValues("salary"))}
          />
          <Button
            variant="solid"
            type="submit"
            bg="primary"
            color="white"
            onClick={() => {
              reset();
              setState("initial");
            }}
            w={"full"}
            _hover={{ bg: "#5050d8" }}
          >
            Check Another Leave Value
          </Button>
        </VStack>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={5}>
            <FormControl id="email">
              <FormLabel>Work Email</FormLabel>
              <InputGroup>
                <Input
                  type="email"
                  size="md"
                  placeholder="ramesh@yourcompanyname.com"
                  focusBorderColor="primary"
                  _hover={{ borderColor: "primary" }}
                  borderColor={errors.email && "red.600"}
                  {...register("email", {
                    required: true,
                    pattern: emailRegex,
                  })}
                />
                {errors.email && (
                  <InputRightElement pointerEvents="none">
                    <Icon as={IoMdAlert} fontSize={"lg"} color="red.600" />
                  </InputRightElement>
                )}
              </InputGroup>
              {errors.email && (
                <Text mt={2} color={"red.600"}>
                  Email is Invalid
                </Text>
              )}
            </FormControl>
            <FormControl id="salary">
              <FormLabel>Your Monthly Salary</FormLabel>
              <InputGroup>
                <InputLeftAddon>₹</InputLeftAddon>
                <Input
                  type="number"
                  size="md"
                  inputMode="numeric"
                  placeholder="50000"
                  focusBorderColor="primary"
                  _hover={{ borderColor: "primary" }}
                  borderColor={errors.salary && "red.600"}
                  {...register("salary", {
                    required: true,
                    pattern: /^[0-9]*$/,
                  })}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.salary && (
                  <InputRightElement pointerEvents="none">
                    <Icon as={IoMdAlert} fontSize={"lg"} color="red.600" />
                  </InputRightElement>
                )}
              </InputGroup>
              {errors.salary && (
                <Text mt={2} color={"red.600"}>
                  Monthly Salary is Invalid
                </Text>
              )}
            </FormControl>

            <FormControl id="name">
              <Button
                variant="solid"
                type="submit"
                bg="primary"
                color="white"
                _hover={{}}
                isLoading={state === "calculating"}
                isDisabled={
                  state === "calculating" || errors.email || errors.salary
                }
                w={"full"}
              >
                {state === "calculating"
                  ? "Calculating..."
                  : "Check Leave Value"}
              </Button>
            </FormControl>
          </VStack>
        </form>
      )}
    </>
  );
}

const CalculatedLeaveValue = ({ leaveValue }) => {
  return (
    <VStack spacing={0} bg={"#f7f7ff"} py={5} rounded={"lg"} w={"full"}>
      <Text fontSize={"lg"} fontWeight={"semibold"}>
        You have
      </Text>
      <Text fontSize={"5xl"} fontWeight={"semibold"} color={"primary"}>
        ~₹{formatNumberWithCommas(leaveValue) || 0}
      </Text>
      <Text fontSize={"lg"} fontWeight={"semibold"}>
        in paid leaves
      </Text>
    </VStack>
  );
};
