import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
// Default Value
const STATUS_CODE = "404";
const PAGE_TITLE = "Page Not Found";
const PAGE_SUBTITLE = "The page you're looking for does not seem to exist";
const SCREEN_HEIGHT = "100vh";
export const ErrorPage = ({
  statusCode = STATUS_CODE,
  pageTitle = PAGE_TITLE,
  pageSubtitle = PAGE_SUBTITLE,
  screenHeight = SCREEN_HEIGHT,
  titleStyle,
}) => {
  return (
    <Flex h={screenHeight} justifyContent={"center"} alignItems={"center"}>
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bg="primary"
          backgroundClip="text"
        >
          {statusCode}
        </Heading>
        <Text fontSize="xl" fontWeight={"bold"} mt={3} mb={2} sx={titleStyle}>
          {pageTitle}
        </Text>
        <Text color={"gray.500"} mb={6}>
          {pageSubtitle}
        </Text>

        <Link to="/">
          <Button
            bg="primary"
            color="white"
            _hover={{ bg: "primary" }}
            variant="solid"
          >
            Go to Home
          </Button>
        </Link>
      </Box>
    </Flex>
  );
};
