import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import antlerImage from "../../Assets/antler.png";
import deloitte from "../../Assets/deloitte.png";
import swiggyImage from "../../Assets/swiggy.png";
import tataImage from "../../Assets/tata.png";

export default function BrandBar() {
  return (
    <Flex
      justify={"center"}
      align={"center"}
      flexDir={{ base: "column", md: "row" }}
      py={{ base: 5, md: 5 }}
      gap={{ base: 6, md: 10, lg: 12 }}
    >
      <HStack justify={"center"}>
        <Text>backed by</Text>
        <Box h={"25px"}>
          <Image
            src={antlerImage}
            alt="Antler"
            h={"full"}
            w={"full"}
            objectFit={"contain"}
          />
        </Box>
      </HStack>
      <Text>&</Text>
      <HStack justify={"center"}>
        <Text>top execs at</Text>
        {[tataImage, swiggyImage, deloitte].map((image, index) => (
          <Box h={"35px"}>
            <Image
              src={image}
              alt={`Brand Logo ${index}`}
              h={"full"}
              w={"full"}
              objectFit={"contain"}
            />
          </Box>
        ))}
      </HStack>
    </Flex>
  );
}
