import {
  Box,
  Flex,
  Heading,
  Image,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import appHomeImage from "../../Assets/app-home-2.png";
import { PrimaryButton } from "../../Components/PrimaryButton";
import CheckLeaveValueForm from "../CheckLeaveValueForm/CheckLeaveValueForm";
import Dialog from "../Dialog/Dialog";
export default function ForEmployeesBanner() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  return (
    <>
      <Box
        bg={"#f7f7ff"}
        borderRadius={"xl"}
        pos={"relative"}
        pt={{ base: 7, md: 12, "2xl": 24 }}
        px={{ base: 5, md: 10, xl: 12, "2xl": 14 }}
        h={"full"}
        display={"grid"}
        alignItems={"end"}
      >
        <Flex
          justify={"space-between"}
          flexDir={{ base: "column", md: "column", lg: "row" }}
          gap={{ base: 10, md: 10, lg: 0 }}
        >
          <VStack
            alignItems={{ base: "center", md: "flex-start" }}
            spacing={{ base: 5, md: 10 }}
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading
              fontSize={{
                base: "4xl",
                md: "5xl",
                lg: "6xl",
                xl: "6xl",
                "2xl": "7xl",
              }}
              maxW="600px"
            >
              Unlock the cash value of your paid leaves
            </Heading>

            <PrimaryButton onClick={onOpen}>
              Check your leave balance now
            </PrimaryButton>
          </VStack>
          <Flex w={{ base: "full", md: "full", lg: "50%" }} justify={"center"}>
            <Box h={{ base: "300px", md: "320px", lg: "420px" }}>
              <Image
                src={appHomeImage}
                alt="App Home Interface"
                w={"full"}
                h={"full"}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Dialog
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setIsFormSubmitted(false);
        }}
        title={
          isFormSubmitted
            ? "Calculated Leave balance"
            : "Check your leave balance now"
        }
      >
        <CheckLeaveValueForm setIsFormSubmitted={setIsFormSubmitted} />
      </Dialog>
    </>
  );
}
