import { Box, List, ListItem } from "@chakra-ui/layout";

export default function TermsConditions() {
  return (
    <>
      <Box padding={5}>
        Terms of Use Please read on to learn the rules and restrictions that
        govern your use of our Services. These Terms and Conditions (the
        “Terms”) are a binding contract between you and Keeper. If you have any
        questions, comments, or concerns regarding these terms or the Services,
        please contact us at ​ hello@keeper.co.in You must agree to and accept
        all of the Terms, or you don’t have the right to use the Services. Your
        using the Services in any way means that you agree to all of these
        Terms, and these Terms will remain in effect while you use the Services.
        These Terms include the provisions mentioned below, as well as those in
        the ​ Advisory Agreement​ and ​ Privacy Policy​ .
        <List py={3}>
          <ListItem>
            1. You are aware and you accept that all information, content,
            materials, products on the application is protected and secured.
          </ListItem>
          <ListItem>
            2. Except for your own personal and non-commercial use, you agree
            that you shall not copy, reproduce, sell, redistribute, publish,
            enter into a database, display, perform, modify, transmit, license,
            create derivatives from, transfer or in any way exploit any part of
            any information, content, materials, services available from or
            through the application.
          </ListItem>
          <ListItem>
            3. You agree that you will not use the application for any purpose
            that is unlawful or prohibited by these Terms. You also agree you
            will not use the application in any manner that could damage,
            disable or impair the application or interfere with any other
            party’s use, legal rights, or enjoyment of the application.
          </ListItem>
          <ListItem>
            4. You acknowledge that the software and hardware underlying the
            application as well as other Internet-related software which are
            required for accessing the application are the legal property of
            either Keeper or its respective third-party vendors. The permission
            given by Keeper to access the application will not convey any
            proprietary or ownership rights in the above software/hardware. You
            agree that you shall not attempt to modify, translate, disassemble,
            decompile or reverse engineer the software/hardware underlying the
            application or create any derivative product based on such
            software/hardware.
          </ListItem>
          <ListItem>
            5. You understand and accept that not all the products and services
            offered on this application are available in all geographic areas
            and you may not be eligible for all the products or services offered
            by Keeper on the application. Keeper reserves the right to determine
            the availability and eligibility for any product or service offered
            on the application.
          </ListItem>
          <ListItem>
            6. You understand and accept that Keeper is not responsible for the
            availability of content or other services on third-party sites
            linked from the application. You are aware that access of hyperlinks
            to other internet sites is at your own risk and the content,
            accuracy, opinions expressed, and other links provided by these
            sites are not verified, monitored or endorsed by Keeper in any way.
            Keeper does not make any warranties and expressly disclaims all
            warranties express or implied, including without limitation, those
            of merchantability and fitness for a particular purpose, title or
            non-infringement with respect to any information or services or
            products that are available or advertised or sold through these
            third-party platforms.
          </ListItem>
          <ListItem>
            7. If performance is prevented, hindered or delayed by a Force
            Majeure event (as defined below), Keeper shall not be liable for any
            failure to perform any of its obligations under these terms and
            conditions or those applicable specifically to its
            services/facilities, and in such case, its obligations shall be
            suspended for so long as the Force Majeure event continues. “Force
            Majeure Event” means any event, due to any cause beyond the
            reasonable control of Keeper, including without limitations,
            unavailability of any communication systems, breach, or virus in the
            digital processes or payment or delivery mechanism, sabotage, fire,
            flood, explosion, acts of God, civil commotion, strikes or
            industrial action of any kind, riots, insurrection, war, acts of
            government, lockdown, computer hacking, unauthorized access to
            computer data and storage devices, computer crashes, malfunctioning
            in the computer terminal or the systems getting affected by any
            malicious, destructive or corrupting code or program, mechanical or
            technical errors/failures or power shut down, faults or failures in
            telecommunication etc.
          </ListItem>
          <ListItem>
            8. By using the services provided through this application, you
            shall be deemed to have accepted the Terms and Conditions herein
            including the amended terms and conditions published on the
            application from time to time.
          </ListItem>
          <ListItem>
            9. Additionally, by continuing using the application or services of
            Keeper you are confirming that:
            <List className="py-1 space-y-0.5">
              <ListItem>
                (a) You are 18 years of age or older and where you are acting as
                Guardian on behalf of a minor, you have the necessary authority
                to register/sign up for the services on behalf of the minor. If
                Keeper learns that we have collected personal information from a
                person under age 18, we will delete that information as quickly
                as possible. If you believe that a person under 18 may have
                provided us with personal information, please contact us at
                ​hello@keeper.co.in.
              </ListItem>
              <ListItem>
                (b) You have read and understood the Privacy Policy published on
                the website and mobile applications of Keeper. The information
                you provide when you register on the application is true and
                correct. In the event, your information is not accessible online
                and you wish to change or delete your personal information or
                other information that you may have provided, please contact us
                immediately at ​hello@keeper.co.in.
              </ListItem>
              <ListItem>
                (c) Keeper may contact you by phone and/or E-mail and/or SMS or
                any other form of electronic communication in connection with
                your registration, transactions or other financial services
                provided by Keeper. You can always opt to stop receiving any or
                all such communications by writing to ​hello@keeper.co.in.​ You
                can also delete your account at any point of time by writing to​
                hello@keeper.co.in.
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Box>
    </>
  );
}
