import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { IoMdAlert } from "react-icons/io";
const TELEGRAM_BOT_API_URL = process.env.REACT_APP_TELEGRAM_BOT_API_URL;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ContactUsForm = () => {
  const toast = useToast();
  const [state, setState] = useState("initial");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setState("submitting");
    const payload = {
      chat_id: "-908107678",
      text: `New contact form submission\n\nEmail: ${data.email}\nSubject: ${data.subject}\nMessage: ${data.message}`,
    };
    try {
      const response = await axios.post(TELEGRAM_BOT_API_URL, payload);
      if (response.data.ok) {
        toast({
          description:
            "Your query has been submitted. We will get back to you within next 48 hours",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setState("success");
        reset();
      }
    } catch (error) {
      setState("error");
      console.error(error);
    }
  };
  return (
    <>
      <Box
        borderRadius="lg"
        w={"full"}
        borderWidth={1}
        borderColor={"rgb(103, 101, 232,0.4)"}
      >
        <Box m={8} color="#0B0E3F">
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={5}>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <InputGroup>
                  <Input
                    type="email"
                    size="md"
                    placeholder="Your Email"
                    focusBorderColor="primary"
                    _hover={{ borderColor: "primary" }}
                    borderColor={errors.email && "red.600"}
                    {...register("email", {
                      required: true,
                      pattern: emailRegex,
                    })}
                  />
                  {errors.email && (
                    <InputRightElement pointerEvents="none">
                      <Icon as={IoMdAlert} fontSize={"lg"} color="red.600" />
                    </InputRightElement>
                  )}
                </InputGroup>
                {errors.email && (
                  <Text mt={2} color={"red.600"}>
                    Email is Invalid
                  </Text>
                )}
              </FormControl>
              <FormControl id="subject">
                <FormLabel>Subject</FormLabel>
                <InputGroup>
                  <Input
                    type="text"
                    size="md"
                    placeholder="Your Subject"
                    focusBorderColor="primary"
                    _hover={{ borderColor: "primary" }}
                    {...register("subject", { required: true })}
                    borderColor={errors.subject && "red.600"}
                  />
                </InputGroup>
              </FormControl>
              <FormControl id="message">
                <FormLabel>Message</FormLabel>
                <Textarea
                  _hover={{
                    borderRadius: "gray.300",
                    borderColor: "primary",
                  }}
                  focusBorderColor="primary"
                  placeholder="Your Message"
                  {...register("message", {
                    required: true,
                  })}
                  borderColor={errors.message && "red.600"}
                />
              </FormControl>
              <FormControl id="name" float="right">
                <Button
                  variant="solid"
                  type="submit"
                  bg="primary"
                  color="white"
                  _hover={{}}
                  isLoading={state === "submitting"}
                  isDisabled={state === "submitting"}
                >
                  Send Message
                </Button>
              </FormControl>
            </VStack>
          </form>
        </Box>
      </Box>
    </>
  );
};
