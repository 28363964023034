import { Button } from "@chakra-ui/react";
import React from "react";

export const PrimaryButton = ({ children, customStyle, onClick }) => {
  return (
    <>
      <Button
        borderRadius={"lg"}
        fontWeight={500}
        color={"white"}
        bg={"primary"}
        _focus={{ bg: "#5050d8" }}
        paddingX={{ base: "23px", lg: "25px", xl: "25px", "2xl": "35px" }}
        paddingY={{ base: "20px", lg: "25px", xl: "25px", "2xl": "35px" }}
        fontSize={{ base: "md", lg: "lg", xl: "lg", "2xl": "xl" }}
        boxShadow={
          "0px 3px 3px 0px rgba(0, 0, 0, 0.15), 0px 3px 3px 0px rgba(0, 0, 0, 0.15)"
        }
        sx={customStyle}
        _hover={{
          boxShadow:
            "0px 3px 3px 0px rgba(0, 0, 0, 0), 0px 3px 3px 0px rgba(0, 0, 0, 0)",
          bg: "#5050d8",
        }}
        outline={"none"}
        style={{ WebkitTapHighlightColor: "transparent" }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  );
};
