import { extendTheme } from "@chakra-ui/react";
import '@fontsource-variable/eb-garamond';
import '@fontsource-variable/inter';
import "@fontsource/josefin-sans/300.css";
import "@fontsource/josefin-sans/500.css";
import "@fontsource/josefin-sans/700.css";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};
const theme = extendTheme({
  config,
  colors: {
    primary: "#6765E8",
  },
  fonts: {
    // heading: `'Josefin Sans', sans-serif`,
    // body: `'Josefin Sans', sans-serif`,
    body: `'Inter Variable', sans-serif`,
    garamond: `'EB Garamond Variable', serif`
  },
});

export default theme;
