import { useEffect } from "react";
const useDynamicTitle = (title, blogTitle) => {
  useEffect(() => {
    if (blogTitle && !title) {
      document.title = `${blogTitle}`;
    } else {
      document.title = `Keeper - ${title}`;
    }
  }, [title, blogTitle]);
};

export default useDynamicTitle;
