import { Box } from "@chakra-ui/layout";

export default function RefundPolicy() {
  return (
    <>
      <Box p={5}>
        <b>Refund, cancellation, and return policy</b>
        <br />
        <b>Mutual Funds</b>
        <br />
        Investors will use the bank account registered with Wishfin and not any
        other bank account. Money is not transferred to Wiser but is remitted to
        the bank account of Asset Management Companies (AMCs) using third-party
        payment gateway pool account. Transactions will be completed only after
        the successful transfer of money from the customer’s bank account
        registered with Wiser and it is remitted to Asset management companies.
        <br />
        A transaction once completed cannot be canceled since units will be
        allotted by the Asset management company and if the investor wishes to
        withdraw the money, units will have to be redeemed from the Mutual
        Funds. In case of a successful debit of money but a failure of
        transaction or rejection of investment transactions, the money will be
        refunded within 72 hrs to the registered bank account with Wiser.
        <br />
        <b>Gold</b>
        <br />
        Please note, once an order for purchase or sale has been confirmed by
        the customer, refunds or cancellation is not possible. All orders are
        final upon customer confirmation. Deliveries are made only to the
        registered address of the customer. Please keep proof of identity ready
        at the time of delivery as it may be required for verification by the
        courier partner. If no one is available at the registered address to
        take delivery, delivery will be attempted at least once more after which
        the package will be returned to the vault and the customer's balance
        will be credited back with the corresponding grams of gold. Shipping
        charges will be payable again as and when the customer asks for
        delivery.
        <br />
        Customers are required to verify that the package they are accepting has
        not been tampered with and is of acceptable quality. Once delivery has
        been taken by the customer, returns and refunds are not possible. All of
        our products are 100% certified and there should never be any problems
        with quality. In the unlikely event of any issues, please contact
        contact@hypervisorhq.com, we will investigate the matter to resolve any
        concerns or issues.
      </Box>
    </>
  );
}
