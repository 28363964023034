export const forEmployer = [
  {
    question: "Does employer carry the financial risk for repayment of dues?",
    answer:
      "No , Employer does not bear any financial risk for the amount taken by employees",
  },
  {
    question:
      "Can an employee use their leaves while they have taken cash advance for those leaves?",
    answer: "Yes, they can still use their leaves as and when needed.",
  },
  {
    question:
      "Is this chargeable?",
    answer:
      "No, Keeper is free of cost for the employer.",
  },
  {
    question:
      "Do my payroll department need to make changes manually every month at the time of payroll?",
    answer:
      "No, We have tech enabled solutions which means no manual entry for every deduction .",
  },
];

export const forEmployees = [
  {
    question: "How much money can I get?",
    answer: "You can get the amount upto 1x your salary as advance",
  },
  {
    question: "If i take advance will i  be able to take leaves?",
    answer: "You can use your leaves as usual whenever needed.",
  },
  {
    question:
      "Would my manager have visibility that I have taken an advance on leaves?",
    answer:
      "No, Your manager does not have visibility over your leave advance.",
  },
  {
    question:
      "What happens if i have taken advance on leave and also use some paid leaves this month?",
    answer:
      "You can still use the leaves as usual and at the end of month if cash value of your current leaves is less than the amount advanced we make auto-deduction for the difference from salary via employer.",
  },
  // {
  //   question: "How will the repayment happen?",
  //   answer:
  //     "Repayment can be done anytime in 3 years or at the separation whichever is earlier. Employer repays the advance amount taken by employee directly at the time of final settlement.",
  // },
  {
    question: "What is the fees?",
    answer:
      "All the charges are visible at the time of amount sanction.overall cost will depend on your leaves value and advance taken.",
  },
  {
    question:
      "Will my cash value of leaves increase for which i have taken an advance?",
    answer:
      "Yes, Your cash value of leaves increases with every salary increment you get even if you have taken an advance on those leaves",
  },
];
