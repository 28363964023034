import { Box, Heading, Image } from "@chakra-ui/react";
import React from "react";
import { brands } from "../../logos/brandLogos";
import BrandCarousel from "./BrandCarousel";

export const TrustedBy = () => {
  const getDynamicHeight = (index) => {
    if (index === 2 || index === 1) {
      return { width: "35px", height: "75px" };
    }
    return { width: "15px", height: "35px" };
  };
  return (
    <Box
      bg={"#F9F9F9"}
      pb={{ base: 0, md: 5 }}
      pt={6}
      my={{ base: 4, md: 6, lg: 8 }}
    >
      <Box>
        <Heading
          fontSize={{ base: "2xl", md: "4xl" }}
          textAlign={"center"}
          mb={3}
        >
          Trusted by
        </Heading>
        <Box overflow={"hidden"} py={{ base: 6, md: 8 }}>
          <BrandCarousel>
            {brands?.map((item, i) => (
              <Box sx={getDynamicHeight(i)} verticalAlign={"middle"}>
                <Image
                  src={item}
                  w={"full"}
                  h={"full"}
                  objectFit={"contain"}
                  filter={"grayscale(1)"}
                />
              </Box>
            ))}
          </BrandCarousel>
        </Box>
      </Box>
    </Box>
  );
};
