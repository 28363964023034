import {
  Box,
  Flex,
  Heading,
  Highlight,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../../Components/PrimaryButton";

export default function Hero({
  title,
  subtitle,
  btnText,
  image,
  highlightWord,
}) {
  return (
    <Flex
      direction={{ base: "column-reverse", md: "row" }}
      align="center"
      textAlign="center"
      gap={8}
      py={{ base: 7, md: 14, lg: 0 }}
      px={{ base: 5, md: 10, lg: 20 }}
      h={"full"}
      justify={"space-between"}
    >
      <VStack
        spacing={4}
        alignItems={{ base: "center", md: "flex-start" }}
        w={{ w: "full", md: "50%" }}
      >
        <Heading
          fontSize={{
            base: "3xl",
            md: "4xl",
            lg: "5xl",
            xl: "5xl",
            "2xl": "6xl",
          }}
          fontWeight="bold"
          lineHeight="shorter"
          maxW="700px"
          textAlign={{ base: "center", md: "left" }}
        >
          <Highlight query={highlightWord} styles={{ color: "#EB5757" }}>
            {title}
          </Highlight>
        </Heading>
        <Text pb={3} fontSize={{ xl: "base", "2xl": "lg" }}>
          {subtitle}
        </Text>
        <Link to="/contact-us">
          <PrimaryButton>{btnText}</PrimaryButton>
        </Link>
      </VStack>
      <Box w={{ base: "full", md: "40%" }}>
        <Box
          w={{ base: "full", md: "80%", xl: "80%", "2xl": "full" }}
          mx={"auto"}
        >
          <Image src={image} alt="Hero Image" w="full" />
        </Box>
      </Box>
    </Flex>
  );
}
